import { requests } from "@/utils/requests";

export const getReport = async () => {
  const data = await requests({
    url: "/api/admin/bugreport",
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const doneReport = async (report_idx) => {
  const data = await requests({
    url: `/api/admin/bugreport/${report_idx}`,
    method: "PUT",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const delReport = async (report_idx) => {
  const data = await requests({
    url: `/api/admin/bugreport/${report_idx}`,
    method: "DELETE",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const newReport = async (report_content) => {
  const data = await requests({
    url: "/api/service/bugreport",
    method: "POST",
    data: {
      report_content,
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};
