<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>관리자 - 버그리포트</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-container>
          <v-row v-for="bug in reportList" :key="bug.report_idx">
            <v-spacer></v-spacer>
            <v-col cols="12" md="8">
              <BugreportCard :reportData="bug" />
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-row v-if="reportList.length == 0">
            <v-col>
              <h3>아직 버그리포트가 존재하지 않습니다.</h3>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getReport } from "@/api/Bugreport";
import BugreportCard from "@/components/Views/Admin/Bugreport/BugreportCard";
export default {
  components: { BugreportCard },
  mounted() {
    this.getReport();
  },
  methods: {
    async getReport() {
      const data = await getReport();
      if (!data.success) {
        alert("데이터를 불러오는데 문제가 있습니다.");
      } else {
        this.reportList = data.data;
      }
    },
  },
  data() {
    return {
      reportList: [],
    };
  },
};
</script>
