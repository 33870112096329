<template>
  <v-card tile>
    <v-card-title class="pa-0">
      <v-container>
        <v-row style="background: #ccc">
          <v-col class="py-1">
            <p class="ma-0 pa-0 text-caption">
              {{
                reportData.TB_USER_ACCOUNT_LIST.TB_PRST_USER_LEVEL.level_name
              }}
            </p>
            <p class="ma-0 pa-0 text-body-2 font-weight-bold">
              {{ reportData.TB_USER_ACCOUNT_LIST.user_name_first_eng }}
              {{ reportData.TB_USER_ACCOUNT_LIST.user_name_last_eng }}
              ({{ reportData.TB_USER_ACCOUNT_LIST.user_id }})
            </p>
          </v-col>
          <v-col class="py-1">
            <p class="ma-0 pa-0 text-caption text-right">
              {{ reportData.createdAt }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col class="px-0 pb-0">
            <v-card
              v-html="reportData.report_content"
              tile
              class="pa-3"
              elevation="1"
            ></v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col class="px-1 pb-0" cols="auto">
            <v-btn color="success" @click="doneReport"> 완료처리 </v-btn>
          </v-col>
          <v-col class="px-1 pb-0" cols="auto">
            <v-btn color="error" @click="delReport"> 삭제처리 </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { doneReport, delReport } from "@/api/Bugreport";
export default {
  props: {
    reportData: {
      type: Object,
      require: true,
    },
  },
  methods: {
    async doneReport() {
      await doneReport(this.reportData.report_idx);
      this.$router.go();
    },
    async delReport() {
      await delReport(this.reportData.report_idx);
      this.$router.go();
    },
  },
};
</script>
